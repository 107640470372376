import React, { useContext } from 'react';
import * as S from './SavingsPopupV1.styles';
import FunnelContext from '../../context/FunnelContext';

const SavingsPopupV1 = props => {
  const { display, linkText, nextTubText } = props;
  const {
    currentTubIndex,
    currentCategory,
    subscription,
    onetime,
    setShowSavingsPopup
  } = useContext(FunnelContext);
  const products =
    currentCategory === `subscriptions`
      ? subscription?.products
      : onetime?.products;
  const currentProductPrice =
    products[currentTubIndex]?.checkoutData?.discounted_price;
  const nextProductPrice =
    products[currentTubIndex + 1]?.checkoutData?.discounted_price;
  const nextTubPrice = nextProductPrice - currentProductPrice;

  const NextTub = () => {
    if (nextTubPrice) {
      return (
        <S.NextTub>
          {nextTubText} ${nextTubPrice.toFixed(2)}
        </S.NextTub>
      );
    } else {
      return ``;
    }
  };

  const PopupLink = () => {
    return (
      <S.LinkText onClick={() => setShowSavingsPopup(true)}>
        {linkText}
      </S.LinkText>
    );
  };

  const TextDisplay = () => {
    if (display === 'next-tub') {
      return <NextTub />;
    } else {
      return <PopupLink />;
    }
  };

  return (
    <S.Widget>
      <TextDisplay />
    </S.Widget>
  );
};

export default SavingsPopupV1;
