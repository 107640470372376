import React from 'react';
import SavingsPopupV1 from './SavingsPopupV1';

const SavingsPopup = props => {
  const { designVersion } = props;
  switch (designVersion) {
    default:
    case 'version1':
      return <SavingsPopupV1 {...props} />;
  }
};

export default SavingsPopup;
