import styled from 'styled-components';

export const LinkText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    color: #BC2E3E!important;
                `;
      default:
        return `
                    color: #e26447!important;
                `;
    }
  }}
  text-transform: uppercase;
`;

export const Widget = styled.div`
  position: initial;
`;

export const NextTub = styled.div``;
